.file-upload-container {
  display: flex;
  width: 100%;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  overflow: auto;
  margin-bottom: 100px;
  background-color: var(--Colors-GRAY-500);
  .file-wrapper {
    display: flex;
    // width: 720px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    .heading-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      max-width: 650px;
      .file-heading {
        color: var(--lightcream-500-base, #f1f1ef);
        text-align: center;

        /* Bold/text-lg */
        // font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%; /* 27px */
      }
      .file-subheading {
        color: var(--GRAY-White, #fff);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
      .sample-download-btn {
        cursor: pointer;
      }
    }

    .file-box {
      display: flex;
      padding: 32px;
      flex-direction: column;
      align-items: center;
      // gap: 24px;
      align-self: stretch;
      border-radius: 6px;
      // width: auto;
      background: var(--GRAY-600, #1f242b);
      width: 650px;
      .file-icon {
        margin-bottom: 16px;
      }
      .title {
        color: var(--Colors-CREAM-500, #f1f1ef);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        margin-bottom: 5px;
      }
      .subtitle {
        color: var(--GRAY-100, #babcbf);
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 21px */
        margin-bottom: 24px;
      }
    }
    .cchbutton-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 16px;
      align-self: stretch;
    }
  }
  @media (max-width: 650px) {
    .file-wrapper {
      // max-width: 650px;
      background-color: var(--Colors-GRAY-500);
    }
    .file-box {
      // min-width: 650px;
      // width: 100% !important;
      background-color: var(--Colors-GRAY-500);
      // width: 650px;
    }
  }
  .ant-upload {
    padding: 0px !important;
  }
  .ant-upload-btn:focus-visible {
    outline: var(--Colors-PRIMARY-GREEN-600) !important;
  }
  .ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: var(--Colors-PRIMARY-GREEN-600) !important;
  }
  .ant-upload-list {
    margin: 8px 0;
  }
  .ant-upload-list-item {
    margin-top: 0px !important;
  }
  .ant-upload-list-item-container {
    padding: 7px 8px;
    border-radius: 4px;
    background: var(--GRAY-600, #1f242b);
    color: var(--lightcream-500-base, #f1f1ef);
    margin-bottom: 5px;
    .anticon-paper-clip,
    .anticon-loading,
    .anticon-spin,
    .anticon-delete {
      color: var(--lightcream-500-base, #f1f1ef) !important;
    }
    .ant-upload-list-item-name {
      text-align: left;
    }
    .ant-progress-bg {
      background-color: var(--Colors-PRIMARY-GREEN-600) !important;
    }
    .ant-btn {
      min-width: 10px !important;
    }
  }
  .ant-upload-drag {
    height: auto;
  }
}
