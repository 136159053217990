.global-status-chip {
  border-radius: 30px;
  border: 0.5px solid;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  // padding: 1px 12px;
  padding: 0px 12px;
  line-height: 22px;
  align-items: center;
  gap: 8px;
  width: max-content;
  height: 24px;
  .chip-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
