.ant-notification {
  .ant-notification-notice-wrapper {
    border: 0.5px solid #393e45;
    border-radius: 6px;
    background-color: var(--Colors-GRAY-800);
    box-shadow: 0px 6px 6px 0px #00000040;
    // height: 48px;
    .ant-notification-notice-close,
    .ant-notification-notice-close:hover {
      color: var(--Colors-CREAM-500);
      position: static;
    }
    .ant-notification-notice {
      padding: 13px 16px;
      display: flex;
      align-items: center;
      // gap: 28px;
      width: unset;
      .ant-notification-notice-message {
        color: var(--Colors-CREAM-500);
        font-size: 14px;
        font-weight: 500;
        line-height: 150%; /* 21px */
        padding-inline-end: 0;
        margin-bottom: 0;
        min-width: 100px;
        text-wrap: nowrap;
      }
      .ant-notification-notice-icon {
        height: 21px;
      }
      .ant-notification-notice-with-icon {
        display: flex;
        align-items: center;
      }
    }
  }
}
