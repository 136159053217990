.decoder-Container {
  height: 100%;
  // padding: 24px 0px 100px 0px;
  margin: var(--top-header-width) 0px 0px var(--left-menu-width);
  position: relative;
  overflow: auto;
  .card {
    /* padding: 32px; */
    // margin: 0 70px;
    border-radius: 16px;
    background: var(--Colors-CREAM-600, #dbdbd9);
    position: static;
  }
  .decoder-sheet {
    .e-sheet-panel {
      height: 60vh !important;
    }
    .loading-skeleton {
      height: 60vh !important;
    }
    .e-spreadsheet {
      height: 60vh !important;
    }
  }
  .multi-options-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: var(--Dimensions-Corner-Radius-lg, 6px);
    border: 0.5px solid var(--Colors-GRAY-100, #babcbf);
    background: var(--Colors-CREAM-500, #f1f1ef);
    margin: 16px 0;
    .ant-radio-wrapper {
      width: 100%;
      padding: 15px;
      border-bottom: 0.5px solid var(--Colors-GRAY-100, #babcbf);
    }
  }
  .multi-options-box-loading {
    padding: 15px;
    border-radius: var(--Dimensions-Corner-Radius-lg, 6px);
    border: 0.5px solid var(--Colors-GRAY-100, #babcbf);
    background: var(--Colors-CREAM-500, #f1f1ef);
    width: 100%;
  }
  .checked-error {
    color: var(--Color_Theme-Error, #f44336);
    font-size: 12px;
    margin-left: 5px;
    text-align: left;
  }
  .wrapper-label-decoder {
    margin: 25px 25px 10px 25px !important;
    text-align: left;
    display: flex;
    gap: 15px;
    .label {
      color: var(--Colors-CREAM-500) !important;
      font-size: 20px;
      line-height: 30px;
    }
  }
  .entity-name {
    color: var(--Colors-CREAM-500);
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
    padding: 0 24px;
    margin-top: 10px;
    text-align: left;
  }
  .decoder-display-container {
    display: flex;
    gap: 10px;
    width: 100%;
    padding: 15px 25px 0 25px;
    .sheet-left-pannel {
      width: 67%;
    }
    .sheet-right-pannel {
      width: 33%;
      height: 60vh;
      overflow: auto;
      padding: 16px;
      border-radius: var(--Dimensions-Corner-Radius-lg, 6px);
      background: var(--Colors-CREAM-500, #f1f1ef);
      .active-feild {
        box-shadow:
          0 -4px 3px -3px #00a97d,
          0 4px 3px -3px #00a97d;
        border: 1.5px solid #00a97d !important;
      }
      .decoder-form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .decoder-input {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          // gap: 10px;

          .input-heading {
            color: var(--Colors-GRAY-500, #22282f);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 150%; /* 21px */
            text-align: left;
          }
          .input-label {
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            padding: 6px 0;
            width: 45%;
            text-align: left;
          }

          .decoder-select-wrap {
            width: 45%;
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 5px;
            margin-left: 10px;
            .decoder-select {
              width: 100%;
              height: 35px;
              text-align: left;
              /* gap: var(--Dimensions-Spacing-sm, 8px); */
              /* align-self: stretch; */
            }
            .error-message {
              color: var(--red-500-base, #f44336);
              font-size: 12px;
            }
          }
          .input-date {
            width: 45% !important;
            flex-grow: 1;
            height: 35px;
            display: flex;
            // justify-content: end !important;
            justify-content: end !important;
          }
          .input-value,
          .input-date {
            width: 50%;
            color: var(--Colors-GRAY-500, #22282f);
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 21px */
            display: flex;
            padding: var(--Dimensions-Spacing-xs, 4px)
              var(--Dimensions-Spacing-md, 12px);
            align-items: center;
            gap: var(--Dimensions-Spacing-sm, 8px);
            align-self: stretch;
            border-radius: var(--Dimensions-Corner-Radius-md, 4px);
            border: 0.5px solid var(--Colors-GRAY-100, #babcbf);
            background: var(--Colors-CREAM-200, #f9f9f8);
            justify-content: space-between;
            .date-placeholder {
              color: var(--Colors-GRAY-100);
              font-size: 12px;
            }
          }
        }
        .multiple-error {
          display: flex;
          flex-direction: column;
          align-items: start;
          margin-top: -17px;
        }
        .error-message {
          color: var(--red-500-base, #f44336);
          font-size: 12px;
        }
      }
      .custom-decoder-sheet-btn-wrap {
        width: 100%;
        display: flex;
        align-items: end;
        justify-content: end;
        // padding: 0 10px;
        .custom-decoder-sheet-btn {
          width: 60%;
          height: 35px;
          text-align: right;
        }
      }
    }
  }
}
.decoder-track-table-wrapper {
  padding: var(--Dimensions-Spacing-md, 12px)
    var(--Dimensions-Spacing-2xl, 24px) var(--Dimensions-Spacing-lg, 16px)
    var(--Dimensions-Spacing-2xl, 24px);

  .decoder-track-heading-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 13px;
    margin-bottom: 16px;
    h2 {
      color: var(--Colors-CREAM-500, #f1f1ef);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 30px */
    }
    .button-group-wrapper {
      display: flex;
      align-items: flex-start;
      gap: var(--Dimensions-Spacing-md, 12px);
    }
  }
  .decoder-track-table {
    margin-bottom: 36px;
    .table-container {
      border-radius: 6px;
      overflow: hidden;
      .ant-table-tbody {
        .ant-table-cell {
          color: var(--Colors-GRAY-500, #22282f);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
        }
      }
    }
  }
}
.decoder-sheet-container-display {
  background-color: var(--input-border-color);
  width: 100%;
  .wrapper-label {
    display: flex;
    margin-bottom: 25px;

    img {
      margin-right: 20px;
    }
  }
  .label {
    text-align: left;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 30px;
    color: var(--Colors-GRAY-500);
  }
}
.decoder-track-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  padding: 30px 20px 20px;
  border-bottom: 0.5px solid #32373d;
  background: linear-gradient(180deg, var(--Colors-GRAY-500) 0%, #1e242a 100%);
  .decoder-track-header-title {
    color: var(--Colors-CREAM-600);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
  }
  .decoder-engament-text {
    display: flex;
    justify-content: end;
    align-items: center;
    color: var(--Colors-CREAM-600, #dbdbd9);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-align: right;
    line-height: 150%; /* 21px */
    span {
      color: var(--Colors-GRAY-50);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      margin: 0 5px;
    }
  }
}

// Trial Balance
.decoder-trial-balance-upload {
  .header {
    padding: 16px 32px;
    text-align: left;
    color: #fff;

    .title {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 0;
    }
    .sub-title {
      margin-top: 4px;
      font-size: 14px;
    }
  }
  .body {
    display: flex;
    gap: 16px;
    padding: 24px 32px;

    .upload-card {
      flex-direction: 0.65;
      background-color: #1e242a;
      color: #fff;
      border: 1px dashed var(--Colors-GRAY-400);
      min-width: 71%;
      padding: 20px 0;

      .upload-box {
        .file-added {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          margin-bottom: 24px;
          background-color: var(--Colors-GRAY-500);
          padding: 6px 12px;
          border-radius: 4px;
        }
        h3 {
          font-size: 16px;
          font-weight: 700;
        }
        p {
          font-size: 14px;
          color: var(--Colors-CREAM-600);
        }
        .box-width {
          display: flex;
          justify-content: center;
          height: 50px;
          width: 100%;
        }
        .upload-button {
          max-width: 200px;
          margin-inline: auto;
        }
      }
    }

    .template-download-card {
      color: var(--Colors-GRAY-500);

      .heading {
        font-weight: 700;
        margin-bottom: 0;
      }
      .sub-heading {
        margin-top: 4px;
        font-size: 14px;
      }

      .download-button {
        background-color: var(--Colors-GRAY-500);
        color: #fff;
        border-radius: 4px;
        margin-inline: auto;
        font-size: 14px;
      }
    }
  }
}

.trail-balance-mapping-table {
  padding: 32px;

  .table-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;

    h5 {
      color: var(--Colors-CREAM-500);
      font-size: 18px;
      font-weight: 700;
    }
  }
  .ant-select-item-option-content {
    white-space: wrap !important;
  }
}

.draft-balance-and-income-sheet {
  // padding: 16px;
  h3 {
    color: #fff;
    text-align: left;
    font-size: 18px;
    margin-bottom: 19px;
    font-weight: 700;
  }
}
