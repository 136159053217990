.balance-container {
  .wrapper-label {
    display: flex;
    padding: 24px;

    img {
      margin-right: 24px;
    }
  }

  .label {
    font-size: 20px;
    line-height: 30px;
    color: var(--Colors-CREAM-600);
    text-align: left;
  }
}

.heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .right-heading-group {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 35px;
    color: var(--Colors-GRAY-100);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .heading-value {
      color: var(--Colors-CREAM-500);
      font-weight: 600;
    }
  }

  .left-heading {
    display: flex;
    justify-content: start;
    align-items: center;
    color: var(--Colors-CREAM-500);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 15px;
  }
  .currency-value {
    color: var(--Colors-CREAM-500);
    font-size: 14px;
    margin: 5px 15px;
    line-height: normal;
  }
}
.sheet-date1 {
  position: relative;
  .sheet-date-duration1 {
    position: absolute;
    top: -45px;
    right: -70px;
    margin: auto 0;
    color: #fff;
  }
  .sheet-date-duration2 {
    position: absolute;
    top: -45px;
    right: -70px;
    margin: auto 0;
    color: #fff;
  }
}
