@import "./utils/styles/trackerTable.scss";

* {
  font-family: "Plus Jakarta Sans";
}
.App {
  text-align: center;
  background-color: var(--Colors-GRAY-500);
  display: flex;
  flex-direction: column;
  height: 100vh;
}
:root {
  // -----app colors----------

  --Colors-PRIMARY-GREEN-900: #004c38;
  --Colors-PRIMARY-GREEN-800: #006148;
  --Colors-PRIMARY-GREEN-700: #008260;
  --Colors-PRIMARY-GREEN-600: #00a278;
  --Colors-PRIMARY-GREEN-500: #00ad80;
  --Colors-PRIMARY-GREEN-400: #00c290;
  --Colors-PRIMARY-GREEN-300: #00d8a0;
  --Colors-PRIMARY-GREEN-200: #b0f3e2;
  --Colors-PRIMARY-GREEN-100: #d9f9f1;
  --Colors-PRIMARY-GREEN-50: #e6fbf6;

  --Colors-SECONDARY-500: #007ac2;

  --Colors-TERTIARY-900: #6b4b1e;
  --Colors-TERTIARY-800: #8c6228;
  --Colors-TERTIARY-700: #b57e33;
  --Colors-TERTIARY-600: #e8a242;
  --Colors-TERTIARY-500: #ffb248;
  --Colors-TERTIARY-400: #ffc16d;
  --Colors-TERTIARY-300: #ffcb84;
  --Colors-TERTIARY-200: #ffdcab;
  --Colors-TERTIARY-100: #ffe7c6;
  --Colors-TERTIARY-50: #fff7ed;

  --Colors-GRAY-900: #0e1114;
  --Colors-GRAY-800: #13161a;
  --Colors-GRAY-700: #181c21;
  --Colors-GRAY-600: #1f242b;
  --Colors-GRAY-500: #22282f;
  --Colors-GRAY-400: #4e5359;
  --Colors-GRAY-300: #6b6f74;
  --Colors-GRAY-200: #999c9f;
  --Colors-GRAY-100: #babcbf;
  --Colors-GRAY-50: #e9eaea;

  --Colors-CREAM-900: #656564;
  --Colors-CREAM-800: #858583;
  --Colors-CREAM-700: #ababaa;
  --Colors-CREAM-600: #dbdbd9;
  --Colors-CREAM-500: #f1f1ef;
  --Colors-CREAM-400: #f4f4f2;
  --Colors-CREAM-300: #f6f6f4;
  --Colors-CREAM-200: #f9f9f8;
  --Colors-CREAM-100: #fbfbfa;
  --Colors-CREAM-50: #fefefd;

  --Colors-SUCCESS-900: #031f05;
  --Colors-SUCCESS-800: #063d0a;
  --Colors-SUCCESS-700: #095c0e;
  --Colors-SUCCESS-600: #0c7a13;
  --Colors-SUCCESS-500: #0f9918;
  --Colors-SUCCESS-400: #3fad46;
  --Colors-SUCCESS-300: #6fc274;
  --Colors-SUCCESS-200: #9fd6a3;
  --Colors-SUCCESS-100: #cfebd1;
  --Colors-SUCCESS-50: #e7f5e8;

  --Colors-WARNING-900: #331b00;
  --Colors-WARNING-800: #663600;
  --Colors-WARNING-700: #995200;
  --Colors-WARNING-600: #cc6d00;
  --Colors-WARNING-500: #ff8800;
  --Colors-WARNING-400: #ffa033;
  --Colors-WARNING-300: #ffb866;
  --Colors-WARNING-200: #ffcf99;
  --Colors-WARNING-100: #ffe7cc;
  --Colors-WARNING-50: #fff3e6;

  --Colors-ERROR-900: #2e0e0e;
  --Colors-ERROR-800: #7e2626;
  --Colors-ERROR-700: #892929;
  --Colors-ERROR-600: #b73737;
  --Colors-ERROR-500: #e54545;
  --Colors-ERROR-400: #ea6a6a;
  --Colors-ERROR-300: #ef8f8f;
  --Colors-ERROR-200: #f5b5b5;
  --Colors-ERROR-100: #fadada;
  --Colors-ERROR-50: #fcecec;

  --black-color: #191919;
  --light-main-color: #b0e4d7;
  --main-secondary-color: #00a97d;
  --main-disable-color: #006650;
  --button-red: #e54545;
  --button-green: #00a991;
  --background-color: #979797;
  --table-font-color: #979797;
  --secondary-button-color: #8ad7c3;

  --btn-disable-color: rgba(255, 255, 255, 0.12);
  --primary-gradient: linear-gradient(90deg, #00d8a0 0%, #00d8a0 100%);
  --tile-gradient: linear-gradient(180deg, #282e36 0%, #222830 100%);

  --button-disabled-green: #f6f6f6;

  --close-icon-background: #e5e5e5;
  --success-toast-bg: #e7f5e8;
  --success-toast-text: #0e8b16;
  --progress-low: #e54545;
  --PLAIN-WHITE: #ffffff;
  --collapse-disabled: rgba(171, 171, 170, 0.4);
  --secondary-border: #393e45;
  --secondary-background-dark: #1f242b;
  --input-box-shadow: 0px 0px 0px 4px rgba(78, 83, 89, 0.1);
  --cards-border-radius: 6px;
  --Colors-ERROR-50: #fcecec;
  --table-row-hover-color: #ededea;

  --gannt-progress-early-start: #b5dfb7;
  --gannt-progress-late-start: #ffdab0;
  --gannt-progress-early-finish: #5d9efe;
  --gannt-progress-late-finish: #ffaf54;
  --gannt-progress-on-time: #5ebb64;

  --status-review-border: #90bcfe;
  --status-review-bg: #e7f1ff;
  --status-review-color: #0d6efd;

  --status-done-border: #3fad46;
  --CCH-cch-500: #007ac2;
  --CCH-cch-300: #54a6d6;

  --left-menu-width: 64px;
  --top-header-width: 64px;
  --tab-bottom-bar-color: #32373d;

  --Colors-SECONDARY-400: #3d8bfd;
  --Colors-ERROR-300: #ef8f8f;
  --Colors-WARNING-400: #ffa033;
  --Colors-SUCCESS-500: #0f9918;

  --global-border: 0.5px solid var(--Colors-GRAY-100);
  --global-border-secondary: 0.5px solid var(--Colors-GRAY-200);

  --Graph-Palette-item-3: #4abdc5;
  --Graph-Palette-item-4: #5648f9;
  --Graph-Palette-item-5: #ea6a6a;
  // --Colors-TERTIARY-500: #ffb248;
  --Graph-Palette-item-7: #45c9c9;

  --success-border-color: #91d095;
  --error-border-color: #f3a9a9;

  --chart-label-color: #8e8e93;

  --global-disable-row-color: #e7e7e7;
  --card-border-global: 16px;
  --border-global: 6px;
  --header-padding-global: 12px 24px 16px;
  --header-seperator-border-global: 0.5px solid #32373d;

  --error-border-color: #ff5e5e;
  --error-box-shadow: 0px 0px 0px 4px rgba(229, 69, 69, 0.1);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.focus-input {
  &:focus-visible {
    /* outline: var(--Colors-PRIMARY-GREEN-200) auto 1px !important;
            outline-offset: 2px !important; */
    outline: 0.5px solid var(--Colors-GRAY-100) !important;
    border: 0.5px solid var(--Colors-GRAY-400, #4e5359) !important;
    box-shadow: 0px 0px 0px 4px rgba(78, 83, 89, 0.1) !important;
  }
}

.fixed-button-group {
  position: fixed;
  bottom: 0;
  left: var(--left-menu-width);
  padding: 14px 24px;
  width: calc(100% - var(--left-menu-width));
  z-index: 1004;
  border-top: 0.5px solid rgba(78, 83, 89, 0.4);
  background: var(--Colors-GRAY-500);
  box-shadow: 0px -4px 16px 2px rgba(0, 0, 0, 0.08);
}

.inline-button-group {
  margin-top: 32px;
}

.button,
.ant-btn {
  display: flex;
  height: 44px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  font-size: 16px;
  color: var(--Colors-GRAY-500);
  cursor: pointer;
  min-width: 90px;
  border: none;
  white-space: nowrap;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none !important;
    border: none !important;
    transition: none;
    pointer-events: none;
  }
  &:focus-visible {
    outline: var(--Colors-PRIMARY-GREEN-200) auto 1px !important;
    outline-offset: 2px !important;
  }
}

.export-button {
  background-color: var(--Colors-PRIMARY-GREEN-300);
  color: var(--Colors-GRAY-500);
  cursor: pointer;
}
.collapse-button {
  background-color: var(--Colors-CREAM-500);
  color: var(--Colors-GRAY-500);
  font-size: 14px;
  font-weight: 500;
  img {
    filter: invert(100%);
  }
}

.tertiary-button {
  background-color: var(--Colors-CREAM-500);
  color: var(--Colors-GRAY-500);
  font-size: 14px;
  font-weight: 500;
  img {
    width: 20px;
    height: 20px;
  }
}

.cch-button {
  background-color: #007ac2;
  color: var(--Colors-CREAM-50);
  font-size: 14px;
  font-weight: 600;
}
.secondary-buttons,
.ant-btn-link {
  background-color: var(--Colors-CREAM-500) !important;
  color: var(--Colors-GRAY-500) !important;
  font-size: 14px;
  font-weight: 600;
  border: 0.5px solid var(--Colors-GRAY-100);
  &:hover {
    color: var(--Colors-PRIMARY-GREEN-600) !important;
    img {
      filter: invert(39%) sepia(76%) saturate(1998%) hue-rotate(139deg)
        brightness(92%) contrast(101%);
    }
  }
}

.grey-button {
  background-color: var(--Colors-CREAM-500);
  color: var(--Colors-GRAY-500);
  font-size: 14px;
  font-weight: 500;
  img {
    width: 20px;
    height: 20px;
  }
  &:hover {
    color: var(--Colors-PRIMARY-GREEN-600) !important;
    img {
      filter: invert(39%) sepia(76%) saturate(1998%) hue-rotate(139deg)
        brightness(92%) contrast(101%);
    }
  }
}

.table-header-button {
  font-size: 12px;
  height: 36px;
  padding: 16px;
}
.primary-button,
.ant-btn-primary {
  background: var(--primary-gradient) !important;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none !important;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none !important;
    border: none !important;
    transition: none;
    pointer-events: none;
  }
  &:hover {
    background: var(--Colors-PRIMARY-GREEN-500) !important;
    color: var(--PLAIN-WHITE) !important;
    img {
      filter: invert(95%) sepia(100%) saturate(2%) hue-rotate(138deg)
        brightness(107%) contrast(101%);
    }
  }
}
.primary-button-border,
.ant-btn-primary {
  border: 0.5px solid var(--Colors-PRIMARY-GREEN-500, #00ad80);
  background: var(--Colors-CREAM-200, #f9f9f8);
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  color: var(--Colors-PRIMARY-GREEN-500, #00ad80);
  // box-shadow: none !important;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none !important;
    border: none !important;
    transition: none;
    pointer-events: none;
  }
  &:hover {
    background: var(--Colors-PRIMARY-GREEN-500) !important;
    color: var(--PLAIN-WHITE) !important;
    img {
      filter: invert(95%) sepia(100%) saturate(2%) hue-rotate(138deg)
        brightness(107%) contrast(101%);
    }
  }
}

.primary-button-border-2,
.ant-btn-primary {
  border: 0.5px solid var(--Colors-PRIMARY-GREEN-500, #00ad80);
  background: none;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  color: var(--Colors-PRIMARY-GREEN-500, #00ad80);
  // box-shadow: none !important;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none !important;
    border: none !important;
    transition: none;
    pointer-events: none;
  }
  &:hover {
    background: var(--Colors-PRIMARY-GREEN-50) !important;
    color: var(--Colors-PRIMARY-GREEN-500) !important;
    img {
      filter: invert(95%) sepia(100%) saturate(2%) hue-rotate(138deg)
        brightness(107%) contrast(101%);
    }
  }
}
.brown-button,
.ant-btn-primary {
  background: var(--Colors-CREAM-200) !important;
  font-size: 14px;
  font-weight: 600;
  box-shadow: none !important;
  opacity: 0.4;
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
    box-shadow: none !important;
    border: none !important;
    transition: none;
    pointer-events: none;
  }
}

.back-button {
  color: var(--PLAIN-WHITE);
  background-color: var(--Colors-GRAY-500);
  border: none;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    background: var(--Colors-PRIMARY-GREEN-500);
  }
}
.white-button {
  font-size: 14px;
  font-weight: 600;
}
.primary-accept {
  background: var(--button-green);
  color: var(--Colors-GRAY-500);
  font-size: 14px;
  &:disabled {
    background: var(--Colors-GRAY-50);
    color: var(--Colors-CREAM-700);
    opacity: 1;
  }
}
.primary-accept-selected {
  background: var(--button-green);
  color: var(--Colors-GRAY-500);
  font-size: 14px;
  font-weight: 600;
  opacity: 0.4;
}
.primary-decline {
  color: var(--PLAIN-WHITE);
  background-color: var(--button-red);
  border: none;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 600;
  &:disabled {
    background: var(--Colors-GRAY-50);
    color: var(--Colors-CREAM-700);
    opacity: 1;
  }
}
.primary-decline-selected {
  color: var(--PLAIN-WHITE);
  background-color: var(--button-red);
  border: none;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 600;
  opacity: 0.4;
}
.ant-checkbox-checked .ant-checkbox-inner {
  display: flex;
  padding: 3px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background-color: var(--main-secondary-color) !important;
  border: none !important;
}

.ant-radio-wrapper:hover {
  .ant-radio-inner {
    border-color: var(--Colors-CREAM-600);
  }
}
.ant-radio-disabled {
  opacity: 0.5;
  .ant-radio-inner {
    border: 1.5px solid var(--Colors-CREAM-600) !important;
    background-color: var(--Colors-CREAM-50) !important;
  }
}
.ant-spin-fullscreen-show {
  z-index: 1005;
}
.ant-radio-checked .ant-radio-inner {
  border-color: var(--main-secondary-color) !important;
  background-color: var(--main-secondary-color) !important;
}
.ant-radio-input:focus-visible + .ant-radio-inner {
  outline: var(--Colors-PRIMARY-GREEN-200) auto 1px !important;
  outline-offset: 2px !important;
}
.ant-radio-inner {
  border: 1.5px solid var(--Colors-GRAY-100) !important;
  height: 20px !important;
  width: 20px !important;
  background-color: transparent !important;
}
.ant-radio::after {
  border: none !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: var(--Colors-GRAY-200) !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 2px;
  border: 1.5px solid var(--Colors-GRAY-200);
  width: 16px;
  height: 16px;
}
.ant-checkbox .ant-checkbox-inner:after {
  width: 5px;
  height: 8px;
  inset-inline-start: 27%;
}
.ant-input-number-outlined:focus-within {
  border-color: var(--Colors-GRAY-100);
  box-shadow: 0px 0px 0px 4px rgba(78, 83, 89, 0.1);
}
.ant-input-number-outlined:hover {
  border-color: var(--Colors-GRAY-400);
}
.ant-picker-dropdown {
  z-index: 10000000002 !important;
  .ant-picker-header-view button:hover {
    color: var(--Colors-PRIMARY-GREEN-500);
  }
}
.ant-picker-outlined {
  width: 100%;
  border: 0.5px solid var(--Colors-GRAY-100);
  background: var(--Colors-CREAM-200);
  border-radius: 4px;
  &:hover {
    border: 0.5px solid var(--Colors-GRAY-400);
    background: var(--Colors-CREAM-50);
  }
}
.ant-picker-outlined:focus,
.ant-picker-outlined:focus-within {
  box-shadow: var(--input-box-shadow);
  border: 0.5px solid var(--Colors-GRAY-400);
  background: var(--Colors-CREAM-50);
}

.ant-picker-cell-range-start,
.ant-picker-cell-range-end {
  .ant-picker-cell-inner {
    background: var(--Colors-PRIMARY-GREEN-500) !important;
  }
}
.ant-picker-cell-inner::before {
  border: 1px solid var(--Colors-PRIMARY-GREEN-500) !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-in-range:not(
    .ant-picker-cell-disabled
  ):before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(
    .ant-picker-cell-disabled
  ):before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(
    .ant-picker-cell-disabled
  ):before {
  background: var(--Colors-PRIMARY-GREEN-50) !important;
}
.ant-picker-active-bar {
  background: var(--Colors-PRIMARY-GREEN-500) !important;
}
.ant-picker-now-btn {
  color: var(--Colors-PRIMARY-GREEN-500) !important;
}
.ant-picker-cell-selected {
  .ant-picker-cell-inner {
    background: var(--Colors-PRIMARY-GREEN-500) !important;
    /* color: var(--Colors-CREAM-500) !important; */
  }
}
.ant-popover {
  z-index: 1000000000;
}
.ant-picker-month-btn:hover,
.ant-picker-year-btn:hover {
  color: var(--Colors-PRIMARY-GREEN-500) !important;
}
.ant-input-outlined:focus-within {
  border: 0.5px solid var(--Colors-GRAY-400, #4e5359);
  box-shadow: 0px 0px 0px 4px rgba(78, 83, 89, 0.1);
}
.ant-input-outlined:hover {
  border: 0.5px solid var(--Colors-GRAY-100);
  background-color: var(--Colors-CREAM-100);
}
.ant-input-outlined {
  border: 0.5px solid var(--Colors-GRAY-100);
  background-color: var(--Colors-CREAM-100);
}
.ant-switch-checked {
  .ant-switch-inner {
    background: var(--primary-gradient);
  }
  .ant-switch-handle::before {
    background: var(--Colors-CREAM-500) !important;
  }
}
.ant-switch .ant-switch-handle::before {
  background: var(--Colors-GRAY-500);
}
.ant-switch-inner {
  background: var(--Colors-CREAM-500);
}
.ant-modal-wrap {
  z-index: 10000000001 !important;
}
.ant-modal-mask {
  z-index: 10000000000 !important;
}
.primary-input {
  height: 48px;
  width: 100%;
  border-radius: 4px;
}
.ant-input {
  font-family: "Plus Jakarta Sans";
  font-size: 14px;
  font-weight: 500;
}
.ant-input-password-icon {
  font-size: 16px;
}
.ant-form-item-explain-error {
  text-align: left;
  margin-bottom: 22px;
  font-size: 13px;
  color: var(--button-red);
}
.ant-input-status-error {
  border-color: var(--button-red) !important;
  border-width: 0.5px !important;
}
.ant-table-filter-dropdown {
  .ant-dropdown-menu-title-content {
    color: var(--Colors-GRAY-500) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    overflow: hidden;
  }
  .ant-dropdown-menu-title-content > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-inline-start: 0 !important;
  }
  .ant-table-filter-dropdown-btns {
    justify-content: flex-end !important;
    border-top: 1px solid var(--Colors-GRAY-50) !important;
    padding: 12px !important;
    gap: 12px;

    .ant-btn-link,
    .ant-btn-primary {
      height: 31px;
      line-height: 150%;
      padding: 5px 16px;
      min-width: auto;
      border-radius: 4px;
    }
  }
}

.ant-table-filter-trigger.active,
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: var(--Colors-PRIMARY-GREEN-600) !important;
  background: none;
}
.ant-table-filter-trigger {
  display: block !important;
  color: var(--Colors-GRAY-300) !important;
}
.ant-table-column-sorter {
  display: block !important;
  color: var(--Colors-GRAY-300) !important;
}
.ant-pagination-item-link,
.ant-pagination-item {
  border-radius: 2px !important;
  background-color: var(--Colors-CREAM-500) !important;
  font-size: 12px;
  font-weight: 600;
  &:hover {
    background-color: var(--Colors-PRIMARY-GREEN-200) !important;
  }
}
.ant-pagination-item-active {
  background-color: var(--Colors-PRIMARY-GREEN-500) !important;
  border-color: var(--Colors-PRIMARY-GREEN-500) !important;
}
.ant-pagination-item-ellipsis {
  color: var(--Colors-CREAM-500) !important;
}
.ant-pagination-options {
  .ant-select-selector {
    border-radius: 2px !important;
    background-color: var(--Colors-CREAM-500) !important;
    .ant-select-selection-item {
      font-size: 12px;
      font-weight: 600;
    }
  }
}
.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer):focus-within
  .ant-select-selector {
  box-shadow: var(--input-box-shadow) !important;
  border: 0.5px solid var(--Colors-GRAY-400, #4e5359) !important;
}

.custom-select {
  width: 100%;
  /* height: 100%; */
}
.global-select-component {
  .ant-select-selector {
    border: 0.5px solid var(--Colors-GRAY-100) !important;
    border-radius: 4px !important;
    background: var(--Colors-CREAM-200);
    gap: 12px;
  }
  .ant-select-selection-item {
    font-size: 12px;
    font-weight: 600;
  }
  .ant-select-arrow {
    img {
      width: 18px;
    }
  }
}

.global-select-component-secondary {
  .ant-select-selection-item {
    font-size: 14px;
    font-weight: 500;
  }
}

.ant-select-selector {
  border-color: var(--Colors-GRAY-100) !important;
  box-shadow: none !important;
  border: 0.5px solid var(--Colors-GRAY-100);
  border-radius: 4px;
  border-width: 0.5px;
  text-align: left;
  &:hover {
    border: 0.5px solid var(--Colors-GRAY-400, #4e5359) !important;
  }
}
.ant-select-item-option-selected {
  background-color: var(--Colors-CREAM-500) !important;
  color: var(--Colors-GRAY-500) !important;
}
.ant-select-item-option-active {
  background-color: var(--Colors-CREAM-500) !important;
}
.ant-select-dropdown {
  background-color: var(--PLAIN-WHITE);
  border-radius: 4px;
  z-index: 10000000000 !important;
}
/* test css */
.jodit-container {
  height: 700px !important;
}
.notify {
  top: 120px !important;
}

.ant-notification {
  top: 80px !important;
}

.rotate-180 {
  transform: scaleX(-1);
}

.full-width {
  width: 100%;
}
.width-70 {
  width: 70% !important;
}
.width-50 {
  width: 50% !important;
}

.fullscreen-loader {
  z-index: 1000000000000000000;
  .ant-spin-dot {
    color: var(--Colors-PRIMARY-GREEN-300);
  }
}

.select-placeholder {
  color: var(--Colors-GRAY-300);
  font-size: 14px;
  font-weight: 500;
}

.ant-tooltip-global {
  z-index: 1000000000;
  .ant-tooltip-inner {
    line-height: normal;
  }
}
.ant-tooltip-light {
  .ant-tooltip-inner {
    background: var(--Colors-CREAM-500);
    color: var(--Colors-GRAY-500);
  }
  .ant-tooltip-arrow:before {
    background: var(--Colors-CREAM-500);
  }
}
.ant-tooltip-dark {
  .ant-tooltip-inner {
    background: var(--Colors-GRAY-500);
    color: var(--Colors-CREAM-500);
  }
  .ant-tooltip-arrow:before {
    background: var(--Colors-GRAY-500);
  }
}

.ant-dropdown {
  z-index: 10000000000 !important;
  .ant-dropdown-menu {
    padding: 0;
  }
  .ant-dropdown-menu-item {
    padding: 10px var(--Dimensions-Spacing-lg, 16px) !important;
    height: 40px;
    width: 260px;
  }
  .ant-dropdown-menu-item-active,
  .ant-dropdown-menu-item-selected {
    background-color: rgba(233, 234, 234, 0.5) !important;
  }
  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;
    gap: 12px;
    color: var(--Colors-GRAY-300);
    font-size: 13px;
    font-weight: 600;
    line-height: 150%; /* 19.5px */
  }
  /* .ant-btn-sm,
  .ant-btn-sm :hover {
    color: var(--Colors-GRAY-500) !important;
  } */
}

.green-text-color {
  color: var(--Colors-PRIMARY-GREEN-500);
  font-weight: 600;
}
.ant-modal-close:hover {
  background-color: unset !important;
}
.ant-table-column-sorters {
  justify-content: flex-start !important;
  gap: 5px;
}
.ant-picker-range-arrow {
  display: none !important;
}
.ant-radio-button-wrapper-checked {
  border-color: var(--Colors-PRIMARY-GREEN-600) !important;
}
.ant-radio-button-checked {
  color: var(--Colors-PRIMARY-GREEN-600) !important;
}
.ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  )::before {
  background-color: var(--Colors-PRIMARY-GREEN-600) !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: var(--Colors-PRIMARY-GREEN-600) !important;
  background: none !important;
}

.full-screen-modal {
  .ant-modal {
    top: 64px !important;
    left: 64px !important;
    width: calc(100% - 64px) !important;
    height: calc(100% - 64px) !important;
    padding: 0;
    margin: 0;
    .ant-modal-content {
      border-radius: 0;
      height: calc(100vh - 64px);
    }
  }
}
.ellipsis-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.wrapped-text {
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: transparent;
}
.unauthorized {
  color: var(--Colors-CREAM-500);
  font-size: 24px;
  padding: 40px;
}
.e-de-ctn {
  border: none !important;
}
.status-chip {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  .status-dot {
    margin-top: 1px;
    font-weight: 700;
    height: 5px;
    width: 5px;
    border-radius: 50%;
  }
}
.eng-planner-status-chip {
  border-radius: 30px;
  border: 0.5px solid;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  padding: 0px 12px;
  line-height: 19px;
  align-items: center;
  gap: 8px;
  width: max-content;
  height: 24px;
  .chip-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
}
.ant-table-selection-column {
  padding: 16px !important;
}
.ant-table-row.ant-table-row-selected > .ant-table-cell {
  background: var(--table-row-hover-color) !important;
}
.ant-table-cell-row-hover {
  background-color: var(--table-row-hover-color) !important;
}
// .e-rte-content {
//   height: 60vh;
// }
.ant-picker-time-panel-cell-selected {
  .ant-picker-time-panel-cell-inner {
    background: var(--Colors-CREAM-500) !important;
  }
}
.status-chip {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  padding: var(--Dimensions-Spacing-4xs, 1px) var(--Dimensions-Spacing-md, 12px);
  align-items: center;
  .status-dot {
    margin-top: 1px;
    font-weight: 700;
    height: 5px;
    width: 5px;
    border-radius: 50%;
  }
}
.modal-action-buttonGroup {
  display: flex;
  justify-content: end;
  gap: 12px;
  align-items: center;
  width: 100%;
  button {
    height: 40px;
  }
}
.loading-sheet {
  color: var(--Colors-CREAM-500);
  text-align: center;
  font-weight: 600;
  height: 100%;
  .loading-skeleton {
    width: 100%;
    height: 100%;
    background-color: var(--Colors-CREAM-500);
    border-radius: 6px;
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: column;
  }
  .loading-text {
    color: #000;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
  .loading-sub-text {
    color: var(--Colors-GRAY-400, #4e5359);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-bottom: 10px;
  }
}
.ant-select-item-option-content {
  white-space: wrap !important;
}

.mr24-16 {
  margin: 16px 24px;
}
