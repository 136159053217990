.auditPrograms-container-primary {
  height: 100%;
  padding: 24px 70px 100px 70px;
  margin: var(--top-header-width) 0px 0px var(--left-menu-width);
  position: relative;
  overflow: auto;

  .card {
    padding: 32px;
    border-radius: 16px;
    background: var(--Colors-CREAM-600, #dbdbd9);
    position: static;
  }
}
.audit-programs-container {
  height: 100%;
  padding: 0px 0px 60px 0px;
  margin: var(--top-header-width) 0px 0px var(--left-menu-width);
  position: relative;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .ant-collapse-arrow svg {
    font-size: 16px;
    font-weight: 700;
  }
  .card {
    padding: 10px;
    border-radius: var(--card-border-global);
    background: var(--Colors-CREAM-600, #dbdbd9);
    position: static;
  }
  .default-component-view {
    margin: 24px;
  }
}

.auditplanner-container-secondary {
  height: 100%;
  padding: 24px 0px 100px 0px;
  margin: var(--top-header-width) 0px 0px var(--left-menu-width);
  position: relative;
  overflow: auto;
}

.global-skeleton-loading {
  width: 100%;
  flex-grow: 1;
  text-align: center;
  display: flex !important;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-weight: 600;
  color: var(--Colors-GRAY-500);
  border-radius: 6px;
  background-color: var(--Colors-CREAM-600);
  .loading-skeleton {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    padding: 20px;
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
    flex-flow: column;
  }
}
.program-table {
  .program-table-info-wrapper {
    width: 100%;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    // margin-bottom: 16px;
    .title {
      color: var(--Colors-CREAM-500, #f1f1ef);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 27px */
    }

    .program-title-component {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px; // margin-bottom: 16px;
      .overall-status-wrapper {
        display: flex;
        align-items: center;
        gap: var(--Dimensions-Spacing-md, 12px);
        color: var(--Colors-CREAM-600, #dbdbd9);
        font-size: 12px;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
        .global-status-chip {
          display: flex;
          padding: 5px 16px;
          align-items: center;
          gap: 8px;
          color: var(--Colors-GRAY-500, #22282f);
          font-family: "Plus Jakarta Sans";
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 22px; /* 183.333% */
          text-transform: uppercase;
          height: auto;
        }
      }
    }
  }
  .programs-track-table {
    padding: 0 24px;
    .task-name {
      color: var(--Colors-GRAY-500, #22282f);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 21px */
      display: flex;
      gap: 5px;
    }
    .task-status {
      .global-status-chip {
        color: var(--Colors-GRAY-500, #22282f) !important;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px !important; /* 183.333% */
        text-transform: uppercase;
      }
    }
    .view-logs-button {
      display: flex;
      align-items: center;
      gap: var(--Dimensions-Spacing-xs, 6px);
      color: var(--Colors-PRIMARY-GREEN-600, #00a278);

      font-feature-settings:
        "case" on,
        "cpsp" on;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 18px */
    }
  }
}
.upload-view-excel {
  display: flex;
  width: 100%;
  padding: var(--Dimensions-Spacing-lg, 16px)
    var(--Dimensions-Spacing-2xl, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Dimensions-Spacing-md, 12px);
  .upload-title {
    color: var(--Colors-CREAM-500, #f1f1ef);
    // margin-bottom: 24px;
    font-size: 18px;
    font-weight: 600;
    line-height: 150%;
  }
  .upload-view-excel-wrapper {
    display: flex;
    gap: 10px;
    width: 100%;
    height: 56vh;
    .left-panel {
      display: flex;
      width: 70%;

      .excel-empty-placeholder {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1 0 0;
        padding: 43px;
        align-self: stretch;
        border-radius: var(--Dimensions-Corner-Radius-lg, 6px);
        background: var(--Colors-CREAM-600, #dbdbd9);
        .placeholder-icon {
          margin-bottom: 24px;
        }
        .placeholder-title {
          color: var(--Colors-GRAY-500, #22282f);
          font-family: "Plus Jakarta Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%; /* 24px */
        }
        .placeholder-subtitle {
          color: var(--Colors-GRAY-400, #4e5359);

          /* Regular/text-sm */
          font-family: "Plus Jakarta Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%; /* 21px */
        }
      }
    }
    .right-panel {
      width: 30%;
      .file-upload-wrapper {
        display: flex;
        padding: var(--Dimensions-Spacing-2xl, 24px)
          var(--Dimensions-Spacing-lg, 16px);
        flex-direction: column;
        align-items: center;
        gap: var(--Dimensions-Spacing-2xl, 24px);
        align-self: stretch;
        border-radius: var(--Dimensions-Corner-Radius-lg, 6px);
        border: 0.5px dashed var(--Colors-GRAY-400, #4e5359);
        background: var(--Colors-GRAY-600, #1f242b);
        .ant-skeleton-title,
        .ant-skeleton-paragraph li {
          background: linear-gradient(
            90deg,
            var(--Colors-GRAY-400) 35%,
            #00a97d 37%,
            var(--Colors-GRAY-400) 63%
          );
          background-size: 400% 100% !important;
        }
        .upload-box {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: var(--Dimensions-Spacing-lg, 16px);
          align-self: stretch;
          .upload-icon {
            margin-bottom: 16px;
          }
          h3 {
            color: var(--Colors-CREAM-500, #f1f1ef);
            text-align: center;
            font-size: 14px;
            font-weight: 700;
            line-height: 150%; /* 21px */
          }
          p {
            color: var(--Colors-CREAM-600, #dbdbd9);
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 150%; /* 18px */
          }
          .file-added {
            display: flex;
            padding: var(--Dimensions-Spacing-2xs, 4px)
              var(--Dimensions-Spacing-sm, 8px);
            align-items: center;
            gap: var(--Dimensions-Spacing-sm, 8px);
            align-self: stretch;
            border-radius: var(--Dimensions-Corner-Radius-md, 4px);
            background: var(--Colors-GRAY-500, #22282f);
            color: var(--Colors-CREAM-500, #f1f1ef);
            font-size: 12px;
            font-weight: 500;
            justify-content: space-between;
            line-height: 150%; /* 18px */
          }
        }
      }
    }
  }
}
.program-guidance-wrapper {
  .title {
    color: var(--Colors-CREAM-500, #f1f1ef);
    font-size: 18px;
    text-align: left;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 27px */
    padding: 16px 24px 0 24px;
  }
  .guidance-content {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding: var(--Dimensions-Spacing-lg, 16px)
      var(--Dimensions-Spacing-2xl, 24px);
    gap: var(--Dimensions-Spacing-md, 12px);
    height: 61vh;

    .left-panel {
      border-radius: var(--Dimensions-Corner-Radius-lg, 6px);
      background: var(--Colors-CREAM-500, #f1f1ef);
    }
    .right-panel {
      display: flex;
      padding: var(--Dimensions-Spacing-lg, 16px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--Dimensions-Spacing-2xl, 24px);
      align-self: stretch;
      border-radius: var(--Dimensions-Corner-Radius-lg, 6px);
      background: var(--Colors-CREAM-500, #f1f1ef);
      width: 100%;
      height: 57vh;
      overflow: auto;
      #dynamic-json-form {
      }
      .ant-form-vertical {
        width: 100%;
        .form-feild-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          // gap: 10px;
          // margin-bottom: 10px;
          .ant-form-item-row {
            display: grid;
            grid-template-columns: 1fr 2fr;
            width: 100%;
            gap: 20px;
            flex-direction: row;
            flex-wrap: nowrap;
          }
          .ant-form-item-explain-error {
            margin-bottom: 0px !important;
            font-size: 12px;
          }
        }
      }
    }
  }
  .guidance-content-table {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: var(--Dimensions-Spacing-lg, 16px)
      var(--Dimensions-Spacing-2xl, 24px);
    gap: var(--Dimensions-Spacing-md, 12px);
    // height: calc(100% - 132px);\
    // height: 500px;
    height: 58vh;
    .program-sheet {
      margin-bottom: 8px !important;
    }
    .ant-table-wrapper {
      overflow: auto !important;
      margin-bottom: 0;
    }
    .ant-table-container {
      // height: 58vh;
      min-height: 57vh;
      height: 100%;
      overflow: auto;
    }
  }
  .pdf-guidance-content-table {
    display: flex;
    padding: var(--Dimensions-Spacing-lg, 16px)
      var(--Dimensions-Spacing-2xl, 24px);
    gap: var(--Dimensions-Spacing-md, 12px);
    .ant-table-cell {
      font-size: 13px;
      padding: 0;
    }
  }
  .extractRowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 20px;
  }
  .select-wrapper-primary-pdf {
    // display: flex;
    // padding: 24px 24px 32px 24px;
    margin-right: 24px;
    // margin-top: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 6px;
    border: 0.5px solid var(--Colors-GRAY-100, #babcbf);
    background: var(--Colors-CREAM-500, #f1f1ef);
    // width: 30%;
    // height: 36px;
    min-width: 250px;
    .input-select {
      height: 56px;
      width: 100%;
    }
    .ant-select-dropdown {
      position: static;
      border-radius: var(--Dimensions-Corner-Radius-md, 4px);
      border: 0.5px solid var(--Colors-GRAY-100, #babcbf);
      box-shadow: none;
    }
    .ant-select-selector {
      text-align: left;
      padding: 10px 16px !important;
      border-radius: 4px;
      border: 0.5px solid var(--Colors-GRAY-100, #babcbf) !important;
      background: var(--Colors-CREAM-500, #f1f1ef) !important;
    }
    .ant-select-selector:focus-visible {
      border: 0.5px solid var(--Colors-GRAY-400, #4e5359);
      box-shadow: 0px 0px 0px 4px rgba(78, 83, 89, 0.1);
      outline: none;
    }
    .ant-select-dropdown {
      padding: 0 !important;
    }
    .ant-select-item {
      background: var(--Colors-CREAM-500) !important;
      color: var(--Colors-GRAY-500) !important;
      font-size: 14px;
      padding: 12px 25px !important;
      text-align: start;
      &:hover {
        background-color: var(--Colors-GRAY-50) !important;
      }
    }
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: var(--Colors-GRAY-50) !important;
      font-weight: 400 !important;
    }
  }
}
.program-table-header {
  cursor: pointer !important;
  flex-grow: 1;
  margin: -16px -16px !important;
  padding: 16px 16px !important;
}
.active-field-wrapper {
  background-color: rgb(230, 251, 246) !important; /* Light blue background */
  border-left: 3px solid var(--main-secondary-color) !important; /* Blue left border */
  border-right: 3px solid var(--main-secondary-color) !important;
  padding: 10px;
}

.active-field {
  border-color: 2px solid var(--main-secondary-color) !important;
}
.activedoc {
  border: 1px solid var(--main-secondary-color) !important;
}
.active-cell {
  border-bottom: 0.5px solid var(--Colors-GRAY-100);
  // border-bottom: 0.5px solid var(--inputs-border-color);
  padding: 16px 24px !important;
  display: block;
  width: 100%;
  // border: 0.5px solid var(--Colors-PRIMARY-GREEN-400, #00c290);
  // background: var(--Colors-PRIMARY-GREEN-50, #e6fbf6);
}
.non-active-cell {
  display: block;
  padding: 16px 24px;
  width: 100%;
}
.table-cell {
  position: relative;
  .table-cell-loading {
    position: absolute;
    top: 10px;
    left: 50px;
  }
}
.table-cell-disable {
  pointer-events: none;
  opacity: 0.7;
  background-color: var(--Colors-CREAM-600);
}
.view-sheet-wrapper {
  padding: 16px 0px;
}
.program-sheet-view-wrapper {
  padding: 24px;
  .title {
    color: var(--Colors-CREAM-500, #f1f1ef);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 27px */
    padding-bottom: 16px;
    text-align: left;
  }
}
.program-currency-container {
  padding: 24px;
  .title {
    color: var(--Colors-CREAM-500, #f1f1ef);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-align: left;
  }
  .curreny-doller-card {
    display: grid;
    border-radius: 6px;
    grid-template-columns: 1fr 2fr;
    padding: 24px;
    margin-top: 16px;
    .doller-currency {
      text-align: left;
      color: var(--Colors-GRAY-500, #22282f);
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px; /* 140% */
    }
  }
  .program-currency-wrapper {
    margin: 16px 0;
    display: flex;
    padding: var(--Dimensions-Spacing-2xl, 24px) !important;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Dimensions-Spacing-lg, 16px);
    align-self: stretch;
    border-radius: 6px;

    .label {
      color: var(--Colors-GRAY-500, #22282f);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
      display: flex;
    }
    .program-currency {
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 10px;
      align-items: center;

      .inputSelect {
        .curreny-input {
          border: 1px solid var(--Colors-CREAM-100) !important;
          border-right: 1px solid var(--Colors-GRAY-100) !important;
          width: 50%;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          background: var(--Colors-CREAM-100);
        }
        .ant-select-selector {
          border: 1px solid var(--Colors-CREAM-100) !important;
          background: var(--Colors-CREAM-100);
          border-left: 1px solid;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
        .converstion-value {
          color: var(--Colors-GRAY-500, #22282f);
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 24px;
        }
      }
    }
  }
}
.sampling-summary-container {
  padding: 24px;
  .title {
    color: var(--Colors-CREAM-500, #f1f1ef);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-align: left;
    margin-bottom: 16px;
  }
  .sampling-table {
    .ant-table-cell {
      color: var(--Colors-GRAY-500, #22282f);
    }
    .last-row {
      color: var(--Colors-GRAY-500, #22282f);
      font-size: 16px !important;
      font-style: normal;
      font-weight: 700 !important;
      line-height: 28px; /* 140% */
      .ant-table-cell {
        border-top: 0.5px solid var(--Colors-GRAY-500);
      }
    }
  }
}
.sampling-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
