.spinner-div {
  display: flex !important;
  flex-direction: column;
  gap: 12px;
  width: unset !important;
  height: unset !important;
  align-items: center;
  inset-inline-start: 40% !important;
  color: var(--Colors-GRAY-500);
  font-size: 14px !important;
  margin: 0 auto;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  img {
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.global-close-icon {
  border-radius: 50%;
  background: var(--close-icon-background);
  width: 24px;
  height: 24px;
  display: flex;
  padding: 7px;
}

.insights-placeholder {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 50vh;
  .message {
    font-family: Arial, sans-serif;
    margin: 0;
    color: var(--Colors-GRAY-500);
    text-align: center;
    font-weight: 500;
    line-height: 150%; /* 21px */
    width: 80%;
  }
}

.fallback-placeholder {
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 50vh;
  background: var(--Colors-CREAM-500);
  border-radius: 6px;
  .insight-icon {
    font-size: 40px;
  }
  .message {
    font-family: Arial, sans-serif;
    margin: 0;
    color: var(--Colors-GRAY-500);
    text-align: center;
    font-weight: 500;
    line-height: 150%; /* 21px */
    width: 80%;
  }
}

.onboarding-suspense-card {
  padding: 32px;
  border-radius: 16px;
  background: var(--Colors-CREAM-600, #dbdbd9);
  position: static;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  .loading-default-wrapper {
    display: flex;
    width: 100%;
    padding: 24px;
    gap: 20px;
    flex-direction: column;
    .load-img-title {
      display: flex;
      width: 100%;
      gap: 20px;
    }
    .card {
      padding: 32px;
      border-radius: 16px;
      background: var(--Colors-CREAM-500);
      position: static;
      border: none;
    }
  }
}

.no-data-placeholder {
  background: var(--Colors-CREAM-600);
  height: 100%;
  border-radius: var(--border-global);
  padding: 24px;
}

.no-data-placeholder {
  background: var(--Colors-CREAM-600);
  height: 100%;
  border-radius: var(--border-global);
  padding: 24px;
}
