.left-menu {
  width: 64px;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex !important;
  flex-direction: column;
  gap: 24px;
  /* padding: 64px; */
  /* border-right: 0.5px solid var(--Colors-GRAY-500); */
  background: var(--Colors-GRAY-900);
  transition: width 0.1s ease-in-out;
  z-index: 9999999999999;
  .open-drawer-button {
    background: var(--Colors-GRAY-900);
    width: 14px;
    height: 68px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    right: -14px;
    clip-path: polygon(0 0%, 100% 15%, 100% 85%, 0 100%);
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .menu-items {
    display: flex !important;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    width: 220px;
    z-index: 9999999999999;
    .menu-item {
      width: 204px;
      .description {
        display: inline;
      }
    }
    .header-item {
      a {
        .description {
          text-align: left;
          display: inline;
        }
      }
    }
  }
  .selected {
    background: var(--Colors-PRIMARY-GREEN-500);
    cursor: pointer;
  }
  .header-item {
    transition: width 0.1s ease-in-out;
    border-bottom: 0.5px solid var(--Colors-GRAY-500);
    a {
      display: flex !important;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      padding: 10px;
      text-decoration: none;
      .description {
        display: none; /* Initially hide the span */
        font-size: 14px;
        font-weight: 600;
        color: var(--Colors-CREAM-500);
        white-space: nowrap;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .menu-item {
    border-radius: 6px;
    transition: width 0.1s ease-in-out;
    width: 48px;
    height: 40px;
    flex-shrink: 0;
    padding: 10px 14px;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    img {
      height: 20px;
      width: 20px;
    }
    .description {
      display: none; /* Initially hide the span */
      font-size: 13px;
      font-weight: 500;
      color: var(--Colors-CREAM-500);
      white-space: nowrap;
    }
    &:not(.selected):hover {
      cursor: pointer;
      .icon {
        filter: invert(51%) sepia(39%) saturate(1228%) hue-rotate(120deg)
          brightness(90%) contrast(101%);
      }
      .description {
        color: var(--Colors-PRIMARY-GREEN-500);
      }
    }
    .icon-selected {
      filter: brightness(0) saturate(100%) invert(11%) sepia(3%) saturate(3558%)
        hue-rotate(172deg) brightness(98%) contrast(89%);
    }
    .icon {
      filter: brightness(0) saturate(100%) invert(95%) sepia(22%) saturate(252%)
        hue-rotate(350deg) brightness(115%) contrast(89%);
    }
  }
}
