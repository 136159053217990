.generator-progress-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;

  .wrapper-label {
    display: flex;
    margin-bottom: 25px;

    img {
      margin-right: 20px;
    }
  }

  .label {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 30px;
    color: var(--Colors-GRAY-500);
    text-align: left;
  }

  .progress-bar-box {
    display: flex;
    flex-direction: column;
    background-color: var(--Colors-CREAM-500);
    padding: 20px;
    border-radius: 12px;
    width: 100%;

    .title {
      color: var(--black-color);
      font-size: 30px;
      font-weight: 700;
      text-align: left;
      margin: 15px 0;
    }

    .sub-title {
      color: var(--black-color);
      font-size: 18px;
      font-weight: 600;
      text-align: left;
      margin-bottom: 20px;
    }

    .info-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--black-color);
      font-size: 14px;
      margin: 10px 0;
    }
  }
}
.download-info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
  justify-content: end;
}
