.aida-chat-bot {
  position: fixed;
  bottom: 90px;
  right: 0px;
  z-index: 1005;
  .aida-icon {
    position: relative;
    bottom: 20px;
    right: 15px;
  }
  .chatbot-box {
    display: flex;
    width: 400px;
    margin-right: 20px;
    height: 580px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-radius: var(--Dimensions-Corner-Radius-xl, 8px);
    background: var(--Colors-CREAM-500, #f1f1ef);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    .header-wrapper {
      display: flex;
      width: 400px;
      padding: var(--Dimensions-Spacing-md, 12px)
        var(--Dimensions-Spacing-lg, 16px);
      align-items: center;
      gap: 14px;
      justify-content: space-between;
      border-bottom: 2px solid var(--Colors-GRAY-50, #e9eaea);
      background: var(--Colors-CREAM-200, #f9f9f8);
      .header-content {
        display: flex;
        width: 180px;
        align-items: center;
        gap: var(--Dimensions-Spacing-md, 12px);
        .bot-title-wrapper {
          .title {
            color: var(--Colors-GRAY-500, #22282f);
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px; /* 140% */
          }
          .subtitle {
            color: var(
              --PRIMARY-GREEN-400,
              var(--Colors-PRIMARY-GREEN-400, #00c290)
            );
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%; /* 18px */
            span {
              fill: var(--Colors-PRIMARY-GREEN-400, #00c290);
              width: var(--Dimensions-Spacing-sm, 8px);
              height: var(--Dimensions-Spacing-sm, 8px);
            }
          }
        }
      }
      .header-action {
        display: flex;
        gap: 15px;
      }
    }
    .body-wrapper {
      display: flex;
      height: 575px;
      padding: 0px var(--Dimensions-Spacing-2xl, 24px);
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      flex-grow: 1;
      overflow-y: auto;
      //   flex-grow overflow-y-auto

      .message {
        display: flex;
        padding: var(--Dimensions-Spacing-lg, 16px)
          var(--Dimensions-Spacing-2xl, 0px);
        justify-content: center;
        gap: 10px;
        flex-direction: column;
        width: 100%;
        .loading-chat {
          /* HTML: <div class="loader"></div> */
          width: 40px;
          aspect-ratio: 2;
          --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
          background:
            var(--_g) 0% 50%,
            var(--_g) 50% 50%,
            var(--_g) 100% 50%;
          background-size: calc(100% / 3) 50%;
          animation: l3 1s infinite linear;
        }
        @keyframes l3 {
          20% {
            background-position:
              0% 0%,
              50% 50%,
              100% 50%;
          }
          40% {
            background-position:
              0% 100%,
              50% 0%,
              100% 50%;
          }
          60% {
            background-position:
              0% 50%,
              50% 100%,
              100% 0%;
          }
          80% {
            background-position:
              0% 50%,
              50% 50%,
              100% 100%;
          }
        }
        .user-name {
          display: flex;
          align-items: center;
          gap: var(--Dimensions-Spacing-sm, 8px);
          .aida-icon {
            display: flex;
            width: var(--Dimensions-Spacing-2xl, 24px);
            height: var(--Dimensions-Spacing-2xl, 24px);
            padding-bottom: 0px;
            justify-content: center;
            align-items: center;
          }
          .aida-icon-bot {
            display: flex;
            width: var(--Dimensions-Spacing-2xl, 24px);
            height: var(--Dimensions-Spacing-2xl, 24px);
            padding-bottom: 0px;
            justify-content: center;
            align-items: center;
          }
          .name {
            color: var(--Colors-GRAY-300, #6b6f74);
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
          }
        }
        .text-wrapper {
          display: flex;
          padding: var(--Dimensions-Spacing-lg, 16px)
            var(--Dimensions-Spacing-2xl, 24px);
          justify-content: center;
          align-items: center;
          gap: 10px;
          color: var(--Colors-GRAY-500, #22282f);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          .text {
            margin-bottom: 0 !important;
            text-align: left;
          }
        }
        .user-ui {
          border-radius: 16px var(--Dimensions-Corner-Radius-md, 4px) 16px 16px;
          background: var(--Colors-PRIMARY-GREEN-300, #00d8a0);
        }
        .aida-ui {
          border-radius: var(--Dimensions-Corner-Radius-md, 4px) 16px 16px 16px;
          background: var(--Colors-GRAY-00, #fff);
        }
      }
      .text-right {
        align-items: end !important;
      }
      .text-left {
        align-items: start;
      }
    }
    .footer-wrapper {
      display: flex;
      padding: var(--Dimensions-Spacing-2xl, 24px) 20px;
      align-items: center;
      gap: var(--Dimensions-Spacing-3xl, 32px);
      align-self: stretch;
      border-top: 2px solid var(--Colors-GRAY-50, #e9eaea);
      background: var(--Colors-GRAY-00, #fff);
      .input-wrapper {
        color: var(--text-main-message, #444);
        font-family: "Plus Jakarta Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
        flex-grow: 1;
        outline: none;
        input:focus {
          outline: transparent !important;
          & :focus-visible {
            outline: transparent !important;
          }
        }
      }
    }
  }
}
