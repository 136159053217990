.action-button-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.action-button-wrapper-mutliBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .info-text {
    color: var(--Colors-CREAM-500);
    font-size: 14px;
  }
}
.right-side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.back {
  margin: 0px !important;
}

.active {
  background: var(--primary-gradient);
  color: var(--black-color);
  border: none;
}

.not-active {
  color: var(--Colors-CREAM-500);
  background-color: var(--Colors-GRAY-500);
  border: none;
}
.active-secondary {
  background-color: var(--secondary-button-color);
  border: 0.5px solid var(--secondary-button-color);
}
button {
  cursor: pointer;
}
